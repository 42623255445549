import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

const originalWarn = console.warn;
console.warn = (...args) => {
  if (typeof args[0] === 'string' && args[0].includes('NG0955')) {
    return; // Suppress NG0955 warnings
  }
  if (typeof args[0] === 'string' && args[0].includes('Could not find localization source')) {
    return; 
  }
  if (typeof args[0] === 'string' && args[0].includes('localization source')) {
    return; 
  }
  originalWarn(...args);
};