<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    @for (breadcrumb of breadcrumbs; track breadcrumb.id; let index = $index) {
    <li class="breadcrumb-item" [ngClass]="{ active: index === breadcrumbs.length - 1 }">
      @if (breadcrumb.link && i !== breadcrumbs.length - 1) {
      <a [routerLink]="breadcrumb.link" [fragment]="breadcrumb.fragment">
        @if (breadcrumb.icon) {
        <i [class]="breadcrumb.icon"></i>
        }
        {{ breadcrumb.title }}
      </a>
      } @else {
      <a [routerLink]="breadcrumb.link" [fragment]="breadcrumb.fragment">
        @if (breadcrumb.icon) {
        <i [class]="breadcrumb.icon"></i>
        }
        {{ breadcrumb.title }}
      </a>
      }
    </li>
    }
  </ol>
</nav>
