import { Environment } from '@abp/ng.core';

const baseUrl = 'https://app.forumeo.com';

const oAuthConfig = {
	issuer: 'https://auth.forumeo.com/',
	redirectUri: baseUrl,
	clientId: 'EOSaaS_App',
	responseType: 'code',
	scope: 'offline_access EOSaaS',
	requireHttps: true,
};

export const environment = {
	production: true,
	sentryEnvName: 'Production',
	application: {
		baseUrl,
		name: 'EOSaaS',
	},
	oAuthConfig,
	apis: {
		default: {
			url: 'https://api.forumeo.com',
			rootNamespace: 'EOSaaS',
		},
		AbpAccountPublic: {
			url: oAuthConfig.issuer,
			rootNamespace: 'AbpAccountPublic',
		},
		Web: {
			url: 'https://forumeo.com',
			rootNamespace: 'EOSaaS'
		}
	},
	remoteEnv: {
		url: '/getEnvConfig',
		mergeStrategy: 'deepmerge',
	},
} as Environment;
