import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application,
        requiredPolicy: 'EOSaaS.Forums',
      },
  
      {
        path: '/forumevents',
        name: 'Events',
        iconClass: 'pi pi-calendar-plus',
        order: 2,
        layout: eLayoutType.application,
        requiredPolicy: 'EOSaaS.ForumEvents',
      },

      {
        path: '/updates',
        name: 'Updates',
        iconClass: 'pi pi-comments',
        order: 3,
        layout: eLayoutType.application,
        requiredPolicy: 'EOSaaS.MemberUpdates',
      },

      {
        path: '/user-profile',
        name: 'Profile',
        iconClass: 'pi pi-user-edit',
        order: 4,
        layout: eLayoutType.application,
        requiredPolicy: 'EOSaaS.UserProfiles',
      },
      // {
      //   path: '/dashboard',
      //   name: '::Menu:Dashboard',
      //   iconClass: 'fas fa-chart-line',
      //   order: 2,
      //   layout: eLayoutType.application,
      //   requiredPolicy: 'EOSaaS.Dashboard.Host  || EOSaaS.Dashboard.Tenant',
      // },
    ]);
  };
}
